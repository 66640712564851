var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseMessage" },
    [
      _c(
        "a-form-model-item",
        { attrs: { label: "假期类型", prop: "vacationTypeId" } },
        [
          _c(
            "a-select",
            {
              attrs: { placeholder: "请选择假期类型" },
              on: { change: _vm.vacationChange },
              model: {
                value: _vm.form.vacationTypeId,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "vacationTypeId", $$v)
                },
                expression: "form.vacationTypeId",
              },
            },
            _vm._l(_vm.vacationTypeList, function (type) {
              return _c(
                "a-select-option",
                {
                  key: type.vacationTypeId,
                  attrs: { value: type.vacationTypeId },
                  on: {
                    click: function ($event) {
                      _vm.form.vacationTypeCode = type.vacationTypeCode
                    },
                  },
                },
                [_vm._v(_vm._s(type.vacationTypeName))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("BlockTitle", {
        staticClass: "msg-title",
        attrs: { title: "基本信息", "is-bold": true },
      }),
      _c(
        "a-form-model-item",
        { attrs: { label: "假期方案名称", prop: "ruleName" } },
        [
          _c("a-input", {
            attrs: { placeholder: "请输入假期方案名称" },
            model: {
              value: _vm.form.ruleName,
              callback: function ($$v) {
                _vm.$set(_vm.form, "ruleName", $$v)
              },
              expression: "form.ruleName",
            },
          }),
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: "适用范围", prop: "scopes" } },
        [
          _c("CbSelectPersons", {
            attrs: { "data-echo": _vm.form.scopes },
            on: { perModelConfirm: _vm.perModelConfirm },
          }),
        ],
        1
      ),
      _c("LeaveTimeUnit", { attrs: { form: _vm.form } }),
      !["NXJ", "TXJ", "DBJ", "SHJ", "LTJ", "TQJ"].includes(
        _vm.form.vacationTypeCode
      )
        ? _c(
            "a-form-model-item",
            { attrs: { label: "请假时长统计" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.form.vacationDelayType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vacationDelayType", $$v)
                    },
                    expression: "form.vacationDelayType",
                  },
                },
                _vm._l(_vm.vacationDelayList, function (leave) {
                  return _c(
                    "a-radio",
                    {
                      key: leave.codeKey,
                      style: _vm.inlineRadioStyle,
                      attrs: { value: leave.codeKey },
                    },
                    [_vm._v(" " + _vm._s(leave.content) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }